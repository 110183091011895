'use client';

import { useMemo, useState, useEffect } from 'react';

import axiosInstance, { endpoints } from 'src/utils/axios';

import { useGetActivityByDomain } from 'src/api/activities';

import { IOSection, BlockTypes, SectionTypes } from 'src/types/generator';

import { ContentContext } from './content-context';

// ----------------------------------------------------------------------

type ContentProviderProps = {
  children: React.ReactNode;
};

export function ContentProvider({ children }: ContentProviderProps) {
  const { activity } = useGetActivityByDomain();

  const [sections, setSections] = useState<IOSection[]>([]);
  const allContentAuth =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.allContentAuth as boolean) || false;

  const openSite =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.openSite as boolean) || false;

  const authVariantInActivity =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.inActivity as boolean) || false;

  const authVariantInMerchant =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.inMerchant as boolean) || false;

  const authVariantAll =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)?.all as boolean) ||
    false;

  const merchantLogo =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.merchantLogo as boolean) || false;

  const globalSite =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.globalSite as boolean) || false;

  const useBackground =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.useBackground as boolean) || false;

  const backgroundImage =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.backgroundImage as string) || '';

  const feedback =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.feedback as boolean) || false

  const modalId =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.modalId as string) || '';

  const intervalTime =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.intervalTime as string) || '';

  const closeSiteByDate =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.closeSiteByDate as boolean) || false;

  const expirationDate =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.expirationDate as string) || '';

  const useCustomExpiration =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.useCustomExpiration as boolean) || false;

  const numberExpiration =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.numberExpiration as string) || '';

  const unitOfTimeExpiration =
    (sections && Array.isArray(sections) && sections
      ?.find((section) => section.sectionType === SectionTypes.generalSettings)
      ?.data.main.find((block) => block.type === BlockTypes.accessRestrictions)
      ?.unitOfTimeExpiration as string) || '';

  useEffect(() => {
    const activityId = activity?.id;
    if (activityId) {
      getSections();
    }

    async function getSections() {
      try {
        if (activity) {
          const response = await axiosInstance.get(endpoints.generator.getSections, {
            params: { activityId },
          });
          if (response.data) {
            setSections(response.data);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [activity]);

  const memoizedValue = useMemo(
    () => ({
      sections,
      allContentAuth,
      openSite,
      authVariantInActivity,
      authVariantInMerchant,
      authVariantAll,
      merchantLogo,
      globalSite,
      useBackground,
      backgroundImage,
      feedback,
      modalId,
      intervalTime,
      closeSiteByDate,
      expirationDate,
      useCustomExpiration,
      numberExpiration,
      unitOfTimeExpiration
    }),
    [sections],
  );

  return <ContentContext.Provider value={memoizedValue}>{children}</ContentContext.Provider>;
}
