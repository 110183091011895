// eslint-disable-next-line import/no-cycle
import { PopupVariants } from 'src/sections/generate-page/components/popups/types';


export type ButtonColor =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';
export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonVariant = 'contained' | 'outlined' | 'text' | 'soft';

export enum SectionTypes {
  home = 'home',
  catalog = 'catalog',
  product = 'product',
  wallet = 'wallet',
  cart = 'cart',
  blog = 'blog',
  informational = 'informational',
  themeSettings = 'themeSettings',
  generalSettings = 'generalSettings',
  popups = 'popups',
  merchant = 'merchant',
  category = 'category',
  vendor = 'vendor',
  account = 'account', // ??? Is this type real?
}

export type ButtonField = {
  link: string;
  label: string;
  size: ButtonSize | null;
  color: ButtonColor | null;
  variant: ButtonVariant | null;
  modalId?: string;
  isDialogToggler: boolean;
  isBackgroundGradient: boolean;
  firstGradientColor?: string;
  secondGradientColor?: string;
  firstGradientStop?: string;
  secondGradientStop?: string;
  icon: string;
  buttonBorderRadius?: string;
  background?: string;
  buttonVariant?: string;
  buttonBgColor?: string;
  buttonColor?: string;
  isTarget?: boolean;
  target?: string;
};

export type CustomButton = {
  label: string;
  href: string;
  icon?: string;
  size: ButtonSize | null;
  color: ButtonColor | null;
  bgcolor: ButtonColor | null;
  variant: ButtonVariant | null;
  isDialogToggler: boolean;
  modalId?: string;
  show?: boolean;
};

export enum BlockTypes {
  banner = 'banner',
  textblock = 'textblock',
  header = 'header',
  footer = 'footer',
  cards = 'cards',
  swiper = 'swiper',
  filter = 'filter',
  carousel = 'carousel',
  bigdiscountlist = 'bigdiscountlist',
  cardlist = 'cardlist',
  categories = 'categories',
  categorylist = 'categorylist',
  productwithfilter = 'productwithfilter',
  moreitems = 'moreitems',
  newarrivalslist = 'newarrivalslist',
  productcarousel = 'productcarousel',
  ratecomponent = 'ratecomponent',
  servicelist = 'servicelist',
  hotdealscarousel = 'hotdealscarousel',
  multibannerscarousel = 'multibannerscarousel',
  multirowbanner = 'multirowbanner',
  serviceitems = 'serviceitems',
  subscribenewsletter = 'subscribenewsletter',
  trendingitems = 'trendingitems',
  bigbannerscarousel = 'bigbannerscarousel',
  categorybanner = 'categorybanner',
  instagramphotogallery = 'instagramphotogallery',
  salesbanner = 'salesbanner',
  topcategories = 'topcategories',
  searchbarwithbackground = 'searchbarwithbackground',
  popularproductswithmultilevelmenu = 'popularproductswithmultilevelmenu',
  multilinecategories = 'multilinecategories',
  testimonials = 'testimonials',
  productscarouselhovereffect = 'productscarouselhovereffect',
  bestsellingcategories = 'bestsellingcategories',
  blogcard = 'blogcard',
  bloglistarea = 'bloglistarea',
  brandlistcarouselarea = 'brandlistcarouselarea',
  fullwidthbanner = 'fullwidthbanner',
  offerbanner = 'offerbanner',
  productlistcolumn = 'productlistcolumn',
  bestsellingproducts = 'bestsellingproducts',
  cardholderbanner = 'cardholderbanner',
  whitecardbanner = 'whitecardbanner',
  happyhrbanner = 'happyhrbanner',
  b2cbanner = 'b2cbanner',
  b2cwhitecardbanner = 'b2cwhitecardbanner',
  b2chappygiftglobal = 'b2chappygiftglobal',
  b2chappygiftworld = 'b2chappygiftworld',
  b2chappygiftbluecardbanner = 'b2chappygiftbluecardbanner',
  threecolumnsinfo = 'threecolumnsinfo',
  wallet = 'wallet',
  b2bshop = 'b2bshop',
  wallet2 = 'wallet2',
  videobanner = 'videobanner',
  titleandzipline = 'titleandzipline',
  sixtableelements = 'sixtableelements',
  fourcolumnelements = 'fourcolumnelements',
  titleandsubtitle = 'titleandsubtitle',
  threecolumnscardinfowithicons = 'threecolumnscardinfowithicons',
  customtestimonials = 'customtestimonials',
  StandartHorizontalBannerV1 = 'StandartHorizontalBannerV1',
  stepbanner = 'stepbanner',
  combinedstepbanner = 'combinedstepbanner',
  // Happy Gift
  happygift_activitybanner = 'happygift_activitybanner',
  cardsbanner = 'cardsbanner',
  cardsbannerwithbutton = 'cardsbannerwithbutton',
  sixhoverblocks = 'sixhoverblocks',
  themeSettings = 'themeSettings',
  css = 'css',
  cart = 'cart',
  account = 'account',
  catalog = 'catalog',
  businessAccount = 'businessAccount',
  residentAccount = 'residentAccount',
  product = 'product',
  fontSettings = 'fontSettings',
  threecolumncardinfowithicons = 'threecolumncardinfowithicons',
  searchbycategory = 'searchbycategory',
  forcolumnelements = 'forcolumnelements',
  brandscarousel = 'brandscarousel',
  threecolumnsinfowithIcons = 'threecolumnsinfowithIcons',
  standardverticalbanner = 'standardverticalbanner',
  shopbanner = 'shopbanner',
  shopbanner2 = 'shopbanner2',
  shopbanner3 = 'shopbanner3',
  shopbanner4 = 'shopbanner4',
  shopbanner5 = 'shopbanner5',
  shopbanner6 = 'shopbanner6',
  buttonsbannerhappyhr = 'buttonsbannerhappyhr',
  allhappyhrbanner = 'allhappyhrbanner',
  hottestoffers = 'hottestoffers',
  popularcategories = 'popularcategories',
  products = 'products',
  products2 = 'products2',
  myESim = 'myESim',
  chat = 'chat',
  myCoupons = 'myCoupons',
  selectedCoupons = 'selectedCoupons',
  myActiveCoupon = 'myActiveCoupon',
  reverseBlock = 'reverseBlock',
  productDescription = 'productDescription',
  about = 'about',
  productpagination = 'productpagination',
  productdetailsview = 'productdetailsview',
  coupondetailsview = 'coupondetailsview',
  banner1 = 'banner1',
  banner2 = 'banner2',
  banner3 = 'banner3',
  banner4 = 'banner4',
  banner1Carousel = 'banner1Carousel',
  addsCarousel = 'addsCarousel',
  addsCarouselSmall = 'addsCarouselSmall',
  bannerproducts = 'bannerproducts',
  bannerproducts2 = 'bannerproducts2',
  bannerproducts3 = 'bannerproducts3',
  search = 'search',
  catalogboombuy = 'catalogboombuy',
  catalogboombuyzara = 'catalogboombuyzara',
  catalogboombuyzara2 = 'catalogboombuyzara2',
  categoriesboomBuy = 'categoriesboomBuy',
  mainmainbanner = 'mainmainbanner',
  mainzarabanner = 'mainzarabanner',
  headbanner = 'headbanner',
  headzarabanner = 'headzarabanner',
  headzboombuybanner = 'headzboombuybanner',
  productboombuydatailsview = 'productboombuydatailsview',
  provider = 'provider',
  providers = 'providers',
  allProviders = 'allProviders',
  carouselStandardHorizontalBanner = 'carouselStandardHorizontalBanner',
  pastimebanner = 'pastimebanner',
  attractionscoupons = 'attractionscoupons',
  voucherbanner = 'voucherbanner',
  bigbannerhappygift = 'bigbannerhappygift',
  specialcategories = 'specialcategories',
  allshopbanners = 'allshopbanners',
  allshopbanners2 = 'allshopbanners2',
  allshopbanners22 = 'allshopbanners22',
  clubcards = 'clubcards',
  headActiveBanner = 'headActiveBanner',
  bannersCarousel = 'bannersCarousel',
  photoDescription = 'photoDescription',
  networkInfo = 'networkInfo',
  mainxtramainbanner = 'mainxtramainbanner',
  mainxtramainbanner2 = 'mainxtramainbanner2',
  mainxtramainbanner3 = 'mainxtramainbanner3',
  vouchersv1 = 'vouchersv1',
  vouchersv2 = 'vouchersv2',
  vouchersv3 = 'vouchersv3',
  brandscarousel2 = 'brandscarousel2',
  couponsv2 = 'couponsv2',
  bigbannerelements = 'bigbannerelements',
  logocomponent = 'logocomponent',
  AllHappyHoldersBanners = 'AllHappyHoldersBanners',
  SpecialCategoriesHappyHolders = 'SpecialCategoriesHappyHolders',
  greybanner = 'greybanner',
  blackbanner = 'blackbanner',
  MainBannerHolders = 'MainBannerHolders',
  popup = 'popup',
  merchant = 'merchant',
  merchants = 'merchants',
  networks = 'networks',
  filterv2 = 'filterv2',
  accessRestrictions = 'accessRestrictions',
  searchSection = 'searchSection',
  // City People
  headercitypeople = 'headercitypeople',
  bannerwithbalancecitypeople = 'bannerwithbalancecitypeople',
  bannerwithbackgroundpic = 'bannerwithbackgroundpic',
  totalinfocitypeople = 'totalinfocitypeople',
  apartments = 'apartments',
  servicelistcitypeople = 'servicelistcitypeople',
  onebigbutton = 'onebigbutton',
  balancecitypeople = 'balancecitypeople',
  categoriesslider = 'categoriesslider',
  companylistcitypeople = 'companylistcitypeople',
  singleservicecitypeople = 'singleservicecitypeople',
  companydescription = 'companydescription',
  ordercitypeople = 'ordercitypeople',
  historycitypeople = 'historycitypeople',
  supplierhistorycitypeople = 'supplierhistorycitypeople',
  historysearchcitypeople = 'historysearchcitypeople',
  footercitypeople = 'footercitypeople',
  faq = 'faq',
  contactcitypeople = 'contactcitypeople',
  infoField = 'infoField',
  blogs = 'blogs',
  newsletter = 'newsletter',
  reportBlock = 'reportBlock',
  listingReports = 'listingReports',
  blogsTextAfter = 'blogsTextAfter',
  blog = 'blog',
  indentationBlock = 'indentationBlock',
  exchangeOption = 'exchangeOption',
  paymentMethods = 'paymentMethods',
  iframe = 'iframe',
  buttonInfoBanner = 'buttonInfoBanner',
  dividerBlock = 'dividerBlock',
}

export type FieldValue =
  | string
  | null
  | number
  | Record<string, any>
  | Record<string, any>[]
  | boolean
  | ButtonField[]
  | string[]; // file URLs

export type BlockVariants = PopupVariants;

export type BlockType = {
  type: BlockTypes;
  name: string;
  settings: Record<string, any>;
  title?: string;
  contentText?: string;
  actionButtons?: ButtonField[];
  isServiceBlock?: boolean;
  isHidden?: boolean;
  isHideTitle?: boolean;
  variant?: BlockVariants;
  [key: string]: FieldValue | undefined;
};

export type IOSectionType = Record<string, BlockType[]>;

export type IOSection = {
  data: IOSectionType;
  id?: string;
  merchantId: number;
  sectionType: SectionTypes;
  url?: string | null;
  title?: string | null;
};

export type INavItem = {
  label: string;
  icon: string;
  path: string;
  children?: INavItem[];
};

export type NavItem = {
  title: string;
  icon: string;
  path: string;
  children?: { subheader: string; items: INavItem[] }[];
};
